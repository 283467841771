@charset "UTF-8";

// Mixins
// @import "components/prefixer";
@import "components/mixins";
@import "components/color";

// Variables;
@import "components/variables";

// Reset
@import "components/normalize";

// components
@import "components/global";
@import "components/icons-material-design";
@import "components/grid";
@import "components/navbar";
@import "components/roboto";
@import "components/typography";
@import "components/cards";
@import "components/toast";
@import "components/tabs";
@import "components/tooltip";
@import "components/buttons";
@import "components/dropdown";
@import "components/waves";
@import "components/modal";
@import "components/collapsible";
@import "components/chips";
@import "components/materialbox";
@import "components/forms/forms";
@import "components/table_of_contents";
@import "components/sideNav";
@import "components/preloader";
@import "components/slider";
@import "components/carousel";
@import "components/date_picker/default";
@import "components/date_picker/default.date";
@import "components/date_picker/default.time";

@import "import/typography";

$infinity: "infinityregular";
$forum: "forumregular";
$manifesto: "manifestoregular";

.fix {
  clear: both;
}

.stroke {
  width: 100%;
  background: url("../images/home-stroke1.png") center top no-repeat;
  height: 68px;
  margin-top: -30px;
  /* position: relative; */
  /* top: -30px; */
  /* display: none; */
}

h1 {
  font-family: $manifesto;
  text-transform: uppercase;
}
h3 {
  font-family: $infinity;
}

body {
  background-color: #f3f8f1;
}

#litdane-home {
  #home-banner {
    height: 100vh;
    min-height: 820px;
    /* 		background: url('../images/banner-bg.jpg') top center no-repeat;
		background-size: cover; */
    position: relative;
    overflow: hidden;
    #home-banner-background {
      height: 100%;
      width: 100vw;
      background: url("../images/banner-bg.jpg") top center no-repeat;
      background-size: cover;
      position: absolute;
      z-index: -1;
    }
    #home-name {
      text-align: center;
      padding-top: 80px;
      h1 {
        color: white;
        font-size: 6rem;
      }
      h3 {
        color: white;
        font-size: 3.5rem;
      }
    }
    #home-social {
      position: absolute;
      bottom: 80px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: 130px;
      height: 130px;
      // background: url("../images/social/social-wrapper-white.png") center center
      //   no-repeat;
      // background-size: cover;
      .social-icon-wrapper {
        width: 100px;
        height: 100px;
        margin: 15px;
        float: left;
      }
      #github {
        background: url("../images/social/social-github-white.png") center
          center no-repeat;
        background-size: contain;
      }
      #linkedin {
        background: url("../images/social/social-linkedin-white.png") center
          center no-repeat;
        background-size: contain;
      }
      @media #{$medium-and-down} {
        width: 70px;
        height: 70px;
        .social-icon-wrapper {
          width: 60px;
          height: 60px;
          margin: 10px;
          float: left;
        }
      }
    }
    @media #{$medium-and-down} {
      height: 800px;
      min-height: auto;
      #home-banner-background {
        height: 800px;
      }
      #home-name {
        padding-top: 50px;
      }
    }
    @media only screen and (max-width: 1080px) and (min-width: 1024px) {
      #home-name {
        padding-top: 40px;
      }
    }
    @media only screen and (max-width: 370px) {
      #home-name {
        h1 {
          font-size: 5rem;
        }
        h3 {
          font-size: 3rem;
        }
      }
    }
  }

  #intro {
    #conversation-wrap {
      position: relative;
      margin-top: 50px;
      @media #{$large-and-up} {
        margin-top: 20vh;
      }
    }
    #avatar-wrap {
      width: 300px;
      height: 300px;
      text-align: center;
      margin: 0 auto;
      margin-top: 50px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border: 5px solid white;
        max-width: 400px;
      }
      #avatar2 {
        display: none;
      }
      @media #{$large-and-up} {
        margin-top: 80px;
      }
    }
    #music-viz-container {
      position: absolute;
      top: 268px;
      margin-left: auto;
      margin-right: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      @media only screen and (max-width: 992px) {
        top: 238px;
      }
    }
    #music-control {
      text-align: center;
      img {
        height: 30px;
        margin-top: 3px;
      }
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
    #intro-text-wrap {
      padding: 25px 0px;
      max-width: 640px;
      margin: 0 auto;
      .intro-message {
        margin: 10px 0;
        line-height: 1.2;
        .emoji {
          width: 32px;
        }
      }
      .question-triangle {
        width: 0;
        height: 0;
        border-right: 10px solid transparent;
        border-top: 10px solid rgba(66, 75, 80, 0.7);
        border-left: 10px solid rgba(66, 75, 80, 0.7);
        border-bottom: 10px solid transparent;
        float: right;
      }
      .question {
        max-width: calc(100% - 40px);
        padding: 15px 25px;
        background-color: rgba(66, 75, 80, 0.7);
        color: white;
        border-radius: 5px 0 5px 5px;
        float: right;
      }
      .reply-triangle {
        width: 0;
        height: 0;
        border-right: 10px solid rgba(120, 146, 98, 0.3);
        border-top: 10px solid rgba(120, 146, 98, 0.3);
        border-left: 10px solid transparent;
        border-bottom: 10px solid transparent;
        float: left;
      }
      .reply {
        max-width: calc(100% - 40px);
        padding: 15px 25px;
        background-color: rgba(120, 146, 98, 0.3);
        color: black;
        border-radius: 0px 5px 5px 5px;
        float: left;
      }
      @media #{$large-and-up} {
        padding-top: 50px;
      }
    }
  }

  #Container .mix {
    display: none;
  }

  .site-section {
    /* height: 100vh; */
    position: relative;
    padding: 5vh 0;
    min-height: 100vh;
    max-width: 1280px;
    margin: 0 auto;
  }

  #project-list-wrap {
    #project-list {
      .card-content {
        img {
          width: 100%;
        }
        .chip {
          margin-bottom: 10px;
        }
      }
    }
    @media #{$small-and-down} {
      .card-content {
        .col {
          margin-top: 10px;
        }
        .col:first-child {
          margin-top: 0px;
        }
      }
    }
  }

  #project-showcase {
    button {
      margin-bottom: 15px;
    }
    button.active {
      background-color: color("teal", "darken-4");
    }
  }

  #showcase-filter.pinned {
    @media #{$medium-and-up} {
      padding-right: 35px;
    }
    @media #{$large-and-up} {
      padding-right: 320px;
    }
  }

  #maze-container {
    width: 600px;
    height: 600px;
    margin: 0 auto;
    @media only screen and (max-width: 630px) {
      height: 300px;
      width: 300px;
      .maze-row-wrapper {
        height: 100px;
      }
      .maze-element-wrapper {
        width: 100px;
      }
      .maze-element {
        width: 50px;
        height: 50px;
      }
    }
  }

  .maze-row-wrapper {
    clear: both;
    height: 200px;
  }

  .maze-element-wrapper {
    float: left;
    width: 200px;
    text-align: center;
  }

  .maze-element {
    width: 100px;
    height: 100px;
    margin: 5px;
    background-color: color("teal", "base");
    /* float: left; */
    border-radius: 50%;
    cursor: pointer;
    transition: all ease-out 200ms;
    &.mazeElementActive {
      background-color: color("lime", "base");
      @extend .z-depth-1;
    }
    &.mazeElementDisabled {
      background-color: color("teal", "lighten-4");
      &.mazeElementActive {
        background-color: color("lime", "base");
      }
    }
    @media (hover: hover) {
      &:hover {
        background-color: color("lime", "base");
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
      }
      &.mazeElementDisabled {
        &.mazeElementActive {
          &:hover {
            background-color: color("lime", "base");
          }
        }
      }
    }
  }
  .reveal-wrapper {
    height: 200px;
    position: relative;
    text-align: center;
    .display-wrapper {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      &.ng-enter,
      &.ng-leave {
        transition: all ease 0.5s;
      }
      &.ng-enter,
      &.ng-leave.ng-leave-active {
        opacity: 0;
      }
      &.ng-leave,
      &.ng-enter.ng-enter-active {
        opacity: 1;
      }
    }
    @media only screen and (max-width: 630px) {
      h3 {
        font-size: 2.25rem;
      }
    }
  }
  #factsModal {
    /* height: 80%; */
    .model-content {
      padding: 50px;
      font-size: 2rem;
    }
    #closeFact {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  footer {
    text-align: center;
  }
}
