@font-face {
    font-family: 'infinityregular';
    src: url('../fonts/infinity-webfont.woff2') format('woff2'),
         url('../fonts/infinity-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'forumregular';
    src: url('../fonts/forum-regular-webfont.woff2') format('woff2'),
         url('../fonts/forum-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'manifestoregular';
    src: url('../fonts/manifesto-webfont.woff2') format('woff2'),
         url('../fonts/manifesto-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {

-webkit-font-smoothing: antialiased; /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */

-moz-osx-font-smoothing: grayscale; /* Fixes font bold issue in Firefox version 25+ on Mac */

}